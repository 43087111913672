import * as Sentry from '@sentry/nuxt'

const env = useRuntimeConfig().public

Sentry.init({
  environment: env.NODE_ENV,
  dsn: env.SENTRY_DSN,
  tracesSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production. While you're testing, we recommend that you set replaysSessionSampleRate to 1.0. This ensures that every user session will be sent to Sentry. Once testing is complete, we recommend lowering this value in production. We still recommend keeping replaysOnErrorSampleRate set to 1.0.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
